<template>
  <div>
    <ribbon class="has-background-white">
      <div class="column is-12 has-text-centered">
        <img src="/assets/images/interface/icons/005-user.svg" width="64" />

        <h2 class="is-size-3">Manage your account</h2>
      </div>
    </ribbon>

    <ribbon class="has-background-white has-border-top" padding="none">
      <div class="has-padding-left-100 has-padding-right-100">
        <horizontal-nav :routes="routes" :is-borderless="true" />
      </div>
    </ribbon>

    <ribbon class="has-inset-shadow-top">
      <router-view />
    </ribbon>
  </div>
</template>

<script>
export default {
  name: "Account",
  data() {
    return {
      routes: [
        {
          label: "Profile",
          to: { path: "/account/profile" }
        },
        {
          label: "Billing",
          to: { path: "/account/billing" },
          guard: () => !this.isResellerUser
        },
        {
          label: "Activity",
          to: { path: "/account/activity" },
          guard: () => !this.isResellerUser
        }
      ]
    };
  },
  computed: {
    isResellerUser() {
      return this.$store.getters["user/isResellerUser"]();
    }
  },
  mounted() {
    this.$bus.$emit("scrollToTop");
  }
};
</script>
